<template>
  <div class="trip-name-sidebar">
    <div class="trip-name-sidebar--content">
      <v-form ref="form">
        <CRInput id="trip-name-input" v-model="routeName" label="Trip Name" />
      </v-form>
    </div>
    <div class="cr-sidebar-dialog--button-spacer" />
    <div>
      <CRButton
        id="trip-name-save-btn"
        :loading="submitting"
        class="trip-name-sidebar--action-btn"
        color="primary"
        @click="submit"
      >
        Save
      </CRButton>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data() {
    return {
      routeName: null,
    }
  },
  computed: {
    trip() {
      return this.$store.getters['quoteForm/getCurrentTrip']
    },
    currentTripIndex() {
      return this.$store.getters['quoteForm/getCurrentTripIndex']
    },
  },
  mounted() {
    this.routeName = this.trip?.routeName || `Trip ${this.currentTripIndex + 1}`
  },
  methods: {
    ...mapActions({
      showAlert: 'app/showAlert',
      setRouteNameModule: 'quoteForm/setRouteName',
    }),
    close() {
      this.$store.dispatch('app/closeDialog')
    },
    async submit() {
      if (!this.routeName) {
        this.showAlert({
          type: 'error',
          message: 'No trip name given.',
        })
        return
      }
      this.setRouteNameModule({
        tripIndex: this.currentTripIndex,
        routeName: this.routeName,
      })
      this.showAlert({ type: 'success', message: 'Trip Name Updated.' })
      this.close()
    },
  },
}
</script>
<style lang="scss" scoped>
.trip-name-sidebar {
  height: 100%;
  width: 500px;

  &--content {
    flex: 1;
    margin: 40px;
  }

  &--action-btn {
    display: flex;
    position: fixed;
    flex-direction: column;
    font-size: 18px;
    bottom: 0;
    width: 500px !important;
    height: 71px !important;
    padding: 24px 0;
    width: inherit;
    border-radius: 0;
  }
}
</style>
